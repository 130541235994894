import React from "react";
import image from "../Assets/kamal-logo.png";
import './Frontpage.css';
import Aos from "aos";
import logo from "../Assets/kamal-logo.png";



function Frontpage() {
  return (
    <div className="wrapper" >
      <div className="main">
        <div className="wel"> welcome To</div>
        <div className="name"> Kamal</div>
        <div className="banquet"> Celebrations</div>
        <div className="tagline">Celebrate with Elegance</div>
    </div>
    </div>
  );
}

export default Frontpage;
